import { Component } from "react";
import "./Navigation.scss";
import vitaLogo from "../../assets/images/vita-logo.png";
import vfiLogo from "../../assets/images/vfi-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import env from "../../environment.json";
import doodles from "../../assets/images/profile doodles";
import NavMenu from "./NavMenu";
import { faChevronDoubleRight } from "@fortawesome/pro-regular-svg-icons";
// import markerHeart from "../../assets/images/marker-heart.svg";

import {
  faCamera,
  faEye,
  // faBoxOpen,
  faUser,
  faChartBar,
  faHandshake,
  faCalendarStar,
  faTv,
  faBell,
  // faBold,
  faFile,
  // faTreeChristmas,
  // faBrush,
  faMapMarkerAlt,
  faPennant,
  faShop,
  faGear,
  faCircleQuestion,
  faNewspaper,
} from "@fortawesome/pro-light-svg-icons";

import {
  faCamera as faCameraSelected,
  faEye as faEyeSelected,
  // faBoxOpen as faBoxOpenSelected,
  faUser as faUserSelected,
  faChartBar as faChartBarSelected,
  faHandshake as faHandshakeSelected,
  faShop as faShopSelected,
  faCalendarStar as faCalendarStarSelected,
  faTv as faTvSelected,
  faBell as faBellSelected,
  // faBold as faBoldSelected,
  faFile as faFileSelected,
  // faTreeChristmas as faTreeChristmasSelected,
  // faBrush as faBrushSelected,
  faMapMarkerAlt as faMapMarkerAltSelected,
  faPennant as faPennantSelected,
  faGear as faGearSelected,
  faCircleQuestion as faCircleQuestionSelected,
  faNewspaper as faNewspaperSelected,
} from "@fortawesome/pro-solid-svg-icons";
import ToolTip from "../ToolTips/ToolTip";
import PermCheck from "../PermCheck";
import { withRouterStore } from "../Router/routerStore";

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      selected: "",
      userPopUp: false,
      navExecuted: undefined,
      navMenus: [],
      navMenuNr: 0,
    };
    if (!sessionStorage.getItem("selectedNavMenu")) {
      sessionStorage.setItem("selectedNavMenu", "0");
    }

    this.allNavMenus = {};

    // if (!this.navMenus) this.navMenus = [];

    //     window.onhashchange = (e) => {
    //       const nav = window.location.hash.split("/").slice(1).join("/");
    //       this.navMenus.forEach((e, i) => {
    //         if (e.text.toLowerCase() === nav) {
    //           e.execute();
    //           sessionStorage.setItem("selectedNavMenu", i);
    //         }
    //       });
    //       this.setState({});
    //     };

    this.theClick = this.theClick.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    this.allNavMenus = {
      home: {
        icon: faEye,
        iconSelected: faEyeSelected,
        text: "Home",
        execute: () => {
          this.props.collapseSidebar(true);
          this.props.sidebarShow("");
          this.props.contentShow("Home");
          // window.location.hash = "/home";
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "home",
                sidebar: "",
                displayName: "Home",
              },
            ],
          });
          window.location.hash = "/";
        },
        popUp: this.props.notificationAmounts.home,
      },
      activity: {
        icon: faBell,
        iconSelected: faBellSelected,
        text: "Activity",
        execute: () => {
          this.props.collapseSidebar(true);
          this.props.sidebarShow("");
          this.props.contentShow("activity");
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "activity",
                sidebar: "",
                displayName: "activity",
              },
            ],
          });
          window.location.hash = "/activity";
        },
        popUp: this.props.notificationAmounts.activity,
        typeCheck: ["sites", "regions", "events", "places", "articleTypes", "tours", "tourOperators", "specifics"],
        minimumRole: "Admin",
      },
      pages: {
        icon: faFile,
        iconSelected: faFileSelected,
        text: "Pages",

        execute: () => {
          this.props.collapseSidebar(false);
          // this.props.sidebarShow("tree");
          this.props.sidebarShow("");
          this.props.contentShow("pages");
          this.props.store.pagesStore.setPagesStore({
            view_interact_type: "open",
          });
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "pages",
                sidebar: "",
                displayName: "pages",
              },
            ],
          });
          window.location.hash = "/pages";
        },
        popUp: this.props.notificationAmounts.pages,
        typeCheck: "sites",
        minimumRole: "Publisher",
      },
      articles: {
        icon: faNewspaper,
        iconSelected: faNewspaperSelected,
        text: "Articles",
        execute: () => {
          this.props.collapseSidebar(false);
          this.props.sidebarShow("");
          this.props.contentShow("Articles");
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "articles",
                sidebar: "",
                displayName: "articles",
              },
            ],
          });
          window.location.hash = "/articles";
        },
        typeCheck: "articleTypes",
        minimumRole: "Publisher",
      },
      // appearances: {
      //   icon: faBrush,
      //   iconSelected: faBrushSelected,
      //   text: "Appearances",
      //   execute: () => {
      //     this.props.collapseSidebar(false);
      //     // this.props.sidebarShow("tree");
      //     this.props.sidebarShow("");
      //     this.props.contentShow("appearances");
      //     window.location.hash = "/appearances";
      //   },
      //   popUp: this.props.notificationAmounts.appearances,
      // },
      media: {
        icon: faCamera,
        iconSelected: faCameraSelected,
        text: "Media",
        execute: () => {
          this.props.collapseSidebar(false);
          if (this.props.myInfo.profession !== "tour_operator") this.props.sidebarShow("list");
          else this.props.sidebarShow("");
          this.props.contentShow("media");
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "media",
                sidebar: "list",
                displayName: "media",
              },
            ],
          });
          if (this.props.sites.length > 0) {
            this.props.optionClicked(this.props.sites[0]);
          }
          window.location.hash = "/media";
        },
        popUp: this.props.notificationAmounts.media,
        typeCheck: ["sites" /*,"tourOperators", "places"*/], // TODO: These extra allowance do Bárður want. But debate is if it's necessary to change anything before doing it..
        minimumRole: "Collaborator",

        // TODO: Allow, when user has any of the following types: "regions", "places", "tour_operators"
      },
      whatson: {
        icon: faCalendarStar,
        iconSelected: faCalendarStarSelected,
        text: "What's On",
        disabled: env.env.includes("vita.fo"),
        execute: () => {
          this.props.collapseSidebar(false);
          this.props.sidebarShow("whatson");
          this.props.contentShow("whatson");
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "whatson",
                sidebar: "whatson",
                displayName: "What's On",
              },
            ],
          });
          window.location.hash = "/whatson";
        },
        popUp: this.props.notificationAmounts.whatson,
      },
      // {
      //   icon: faBoxOpen,
      //   iconSelected: faBoxOpenSelected,
      //   text: "Modules",
      //   execute: () => {
      //     this.props.collapseSidebar(true);
      //     this.props.sidebarShow("");
      //     this.props.contentShow("");
      //   },
      //   popUp: this.props.notificationAmounts.modules,
      // },
      infoscreens: {
        icon: faTv,
        iconSelected: faTvSelected,
        text: "Infoscreens",
        execute: () => {
          this.props.collapseSidebar(false);
          this.props.sidebarShow("infoscreens");
          this.props.contentShow("infoscreens");
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "infoscreens",
                sidebar: "infoscreens",
                displayName: "infoscreens",
              },
            ],
          });
          window.location.hash = "/infoscreens";
        },
        popUp: this.props.notificationAmounts.infoscreens,
        typeCheck: ["articleTypes"],
        minimumRole: "Publisher",
      },
      persons: {
        icon: faUser,
        iconSelected: faUserSelected,
        text: "Users",

        execute: () => {
          this.props.collapseSidebar(false);
          // if (this.props.myInfo.profession !== "tour_operator")
          //   this.props.sidebarShow("list");
          // else this.props.sidebarShow("");
          this.props.sidebarShow("");
          this.props.contentShow("users");
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "users",
                sidebar: "",
                displayName: "Users",
              },
            ],
          });
          if (this.props.sites.length > 0) {
            this.props.optionClicked(this.props.sites[0]);
          }
          window.location.hash = "/users";
        },
        popUp: this.props.notificationAmounts.users,
        typeCheck: ["sites", "regions", "events", "places", "articleTypes", "tours", "tourOperators"],
        minimumRole: "Admin",
      },
      analytics: {
        icon: faChartBar,
        iconSelected: faChartBarSelected,
        text: "Analytics",

        execute: () => {
          this.props.collapseSidebar(true);
          this.props.sidebarShow("");
          this.props.contentShow("analytics");
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "analytics",
                sidebar: "",
                displayName: "analytics",
              },
            ],
          });
          window.location.hash = "/analytics";
        },
        popUp: this.props.notificationAmounts.analytics,
        typeCheck: ["sites", "regions", "places", "tourOperators"],
        minimumRole: "Publisher",
      },
      collaboration: {
        icon: faHandshake,
        iconSelected: faHandshakeSelected,
        text: "Collaboration",
        disabled: env.env.includes("vita.fo"),
        execute: () => {
          this.props.collapseSidebar(true);
          this.props.sidebarShow("");
          this.props.contentShow("");
        },
        popUp: this.props.notificationAmounts.collaboration,
      },

      // {
      //   icon: faCog,
      //   iconSelected: faCogSelected,
      //   text: 'Settings',

      //   execute: () => {
      //     this.props.collapseSidebar(true);
      //     this.props.sidebarShow('');
      //     this.props.contentShow('');
      //     window.location.hash = '/settings';
      //     // window.history.pushState("","","#/settings");
      //   },
      //   popUp: this.props.notificationAmounts.settings,
      // },
      myplaces: {
        icon: faMapMarkerAlt,
        iconSelected: faMapMarkerAltSelected,
        // iconIs: "image",
        text: "My places",
        disabled: env.env.includes("vita.fo"),
        execute: () => {
          this.props.collapseSidebar(true);
          this.props.sidebarShow("");
          this.props.contentShow("myPlaces");
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "myPlaces",
                sidebar: "",
                displayName: "myPlaces",
              },
            ],
          });
        },
        popUp: this.props.notificationAmounts.myPlaces,
      },
      mytours: {
        icon: faPennant,
        iconSelected: faPennantSelected,
        // iconIs: "image",
        text: "My tours",
        disabled: !env.env.includes("vita.fo"),
        execute: () => {
          this.props.collapseSidebar(true);
          this.props.sidebarShow("");
          this.props.contentShow("myTours");
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "myTours",
                sidebar: "",
                displayName: "myTours",
              },
            ],
          });
        },
        popUp: this.props.notificationAmounts.myTours,
      },
      shop: {
        icon: faShop,
        iconSelected: faShopSelected,
        text: "Shop",
        disabled: env.theme !== "DS",
        execute: () => {
          this.props.collapseSidebar(false);
          this.props.sidebarShow("");
          this.props.contentShow("Shop");
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "Shop",
                sidebar: "",
                displayName: "Shop",
              },
            ],
          });
          window.location.hash = "/shop";
        },
      },
      admin: {
        icon: faGear,
        iconSelected: faGearSelected,
        text: "Admin",
        execute: () => {
          this.props.collapseSidebar(false);
          this.props.sidebarShow("");
          this.props.contentShow("Admin");
          // this.props.store.routerStore.setRouter({ route: [{
          //   nav: 'admin',
          //   sidebar: '',
          //   displayName: "Admin",
          // }] });
          this.props.store.routerStore.navTo(0, {
            nav: "admin",
            sidebar: "",
            displayName: "Admin",
          });
          window.location.hash = "/admin";
        },
        typeCheck: ["sites", "regions"],
        minimumRole: "Publisher",
      },
      help: {
        icon: faCircleQuestion,
        iconSelected: faCircleQuestionSelected,
        text: "Help",
        execute: () => {
          this.props.collapseSidebar(false);
          this.props.sidebarShow("");
          this.props.contentShow("Help");
          this.props.store.routerStore.setRouter({
            route: [
              {
                nav: "help",
                sidebar: "",
                displayName: "Help",
              },
            ],
          });
          window.location.hash = "/help";
        },
      },
    };

    const {
      home,
      activity,
      pages,
      articles,
      // appearances,
      media,
      whatson,
      // modules,
      // infoscreens,
      persons,
      // analytics,
      // collaboration,
      // settings,
      myplaces,
      mytours,
      shop,
      admin,
      // help,
    } = this.allNavMenus;

    const previewUserId = sessionStorage.getItem("preview_user_id");
    if (!previewUserId) {
      if (parseInt(this.props.myInfo.superAdmin) || this.props.myInfo.profession === null) {
        if (this.state.navMenuNr !== 1) {
          this.setState({
            navMenus: [
              home,
              activity,
              pages,
              articles, // For testing
              // appearances,
              media,
              whatson,
              // modules,
              // infoscreens,
              persons,
              shop,
              admin, // For testing
              // help, // For testing
            ],
            navMenuNr: 1,
          });
        }
      } else {
        if (this.props.myInfo.profession === "tour_operator") {
          if (this.state.navMenuNr !== 2) {
            this.setState({
              navMenus: [media, persons, myplaces, mytours],
              navMenuNr: 2,
            });
          }
        } else if (true) {
          if (this.state.navMenuNr !== 3) {
            this.setState({
              navMenus: [
                home,
                activity,
                pages,
                articles,
                // appearances,
                media,
                whatson,
                // modules,
                // infoscreens,
                persons,
                admin,
              ],
              navMenuNr: 3,
            });
          }
        }
      }
    }

    const previewUserProfession = sessionStorage.getItem("preview_user_profession");
    if (previewUserId) {
      if (previewUserProfession === "tour_operator") {
        if (this.state.navMenuNr !== 4) {
          this.setState({
            navMenus: [home, media, persons, myplaces, mytours],
            navMenuNr: 4,
          });
        }
      }
    }

    const selectedNavMenuKey = Object.keys(this.allNavMenus).find(
      (e) => this.allNavMenus[e].text === sessionStorage.getItem("selectedNavMenu")
    );

    if (previewUserId) {
      if (previewUserProfession === "tour_operator") {
        if (this.state.navMenuNr !== 4) {
          this.setState({
            navMenus: [this.allNavMenus[4], this.allNavMenus[6], this.allNavMenus[9], this.allNavMenus[10]],
            navMenuNr: 4,
          });
        }
      }
    }

    const selectedNavMenu = this.allNavMenus[selectedNavMenuKey];

    if (sessionStorage.getItem("selectedNavMenu") !== this.state.navExecuted && selectedNavMenu !== undefined) {
      selectedNavMenu.execute();
      this.setState({ navExecuted: sessionStorage.getItem("selectedNavMenu") });
    }
    if (
      prevProps.newNav !== this.props.newNav ||
      prevProps.myInfo.superAdmin !== this.props.myInfo.superAdmin ||
      prevProps.myInfo.userId !== this.props.myInfo.userId
    ) {
      sessionStorage.setItem("selectedNavMenu", this.props.newNav);
      this.setState({});
    }
  }

  theClick(e, index) {
    this.props.updateNewNav(index);
    this.state.navMenus.forEach((element, i) => {
      if (index === i) {
        // sessionStorage.setItem("selectedNavMenu", index);
        sessionStorage.setItem("selectedNavMenu", e.text);
        e.execute();
      }

      // this.setState({ collapsed: true });
    });
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.activityUpdate !== this.props.activityUpdate) {
  //     this.setState({});
  //   }
  // }

  userPopUp = () => {
    var userPopUp = (
      <div className={"userPopUp" + (this.state.userPopUp ? "" : " hidden")}>
        <div className="image">
          {this.props.myInfo.localImg === undefined || this.props.myInfo.localImg === null ? (
            <img src={doodles[this.props.myInfo.userId % 12]} alt="empty" />
          ) : (
            <img
              src={
                this.props.myInfo.user_image
                  ? env.protocol +
                    env.env +
                    "/uploads/" +
                    this.props.myInfo.user_image.file_name +
                    "_thumbnail." +
                    this.props.myInfo.localImg_extension
                  : ""
              }
              alt="user img"
            />
          )}
        </div>
        <div className="info">
          <p className="textBlack">{this.props.myInfo.firstname}</p>
          <p className="textLight">{this.props.myInfo.email}</p>
          <div className="btn" onClick={() => this.props.navigateToUser("profile")}>
            <p>View profile</p>
          </div>
        </div>

        <div className="signOut-container">
          <div className="settings-btn" onClick={() => this.props.navigateToUser("settings")}>
            Settings
          </div>
          <div className="signOut-btn" onClick={() => this.props.logout()}>
            Sign out
          </div>
        </div>
      </div>
    );

    return userPopUp;
  };

  render() {
    const collapsed = this.state.collapsed ? "collapsed " : "";

    return (
      <div
        key={this.props.myInfo.userId}
        className={"navigation " + collapsed}
        onMouseLeave={() => this.setState({ userPopUp: false })}
      >
        <ToolTip
          offset={{ x: 30, y: 30 }}
          priority={"right,bottom"}
          title=""
          delay={600}
          toolTipElements={<div>Toggle navigation</div>}
          className={"top-logo "}
          onClick={() => {
            this.setState({ collapsed: !this.state.collapsed });
          }}
        >
          <img
            className={env.theme === "DS" ? "invertLogo" : ""}
            src={env.theme === "DS" ? vitaLogo : vfiLogo}
            alt="sheep"
          />
          <FontAwesomeIcon className={"icon "} icon={faChevronDoubleRight} />
        </ToolTip>
        {this.state.navMenus.map((element, i) => {
          if (element && element.disabled) {
            return "";
          }
          return (
            <PermCheck
              type={element.typeCheck}
              minimumRole={element.minimumRole}
              ignoreCheck={element.typeCheck === undefined}
              // ignoreCheck={true}
              key={`navigation-${element.text}-${i}}`}
            >
              <ToolTip
                offset={{ x: 20, y: 10 }}
                priority={"right,bottom"}
                title=""
                delay={1000}
                toolTipElements={<div>Go to {element.text}</div>}
                className="nav-menu-parent"
              >
                <NavMenu
                  icon={element.icon}
                  iconSelected={element.iconSelected}
                  iconIs={element.iconIs}
                  text={element.text}
                  // popUp={this.props.activityUpdate}
                  popUp={element.popUp}
                  onClick={() => {
                    if (this.props.confirmBeforeNavigate === "") this.theClick(element, i);
                    else if (window.confirm(this.props.confirmBeforeNavigate)) this.theClick(element, i);
                  }}
                  selected={
                    this.props.store.routerStore.route[0].displayName?.toLowerCase() === element.text?.toLowerCase()
                    // sessionStorage.getItem("selectedNavMenu") === element.text
                    //   ? true
                    //   : false
                  }
                />
              </ToolTip>
            </PermCheck>
          );
        })}
        {/* <button
          onClick={() => {
            uploadAllOldWhatsonPlaces();
          }}
        >
          Test
        </button> */}

        <div
          className="nav-menu-parent user"
          onMouseEnter={() => this.setState({ userPopUp: true })}
          onClick={() => {
            this.setState({ userPopUp: !this.state.userPopUp });
          }}
        >
          <div className={"nav-menu " + (false ? "selected" : "")}>
            {this.props.myInfo.localImg === undefined || this.props.myInfo.localImg === null ? (
              <div className="image">
                <img src={doodles[this.props.myInfo.userId % 12]} alt="empty" />
              </div>
            ) : (
              <div className="image">
                <img
                  src={
                    this.props.myInfo.user_image
                      ? env.protocol +
                        env.env +
                        "/uploads/" +
                        this.props.myInfo.user_image.file_name +
                        "_thumbnail." +
                        this.props.myInfo.localImg_extension
                      : ""
                  }
                  alt="user img"
                />
                {/* <FontAwesomeIcon icon={this.props.icon} /> 
               Vísa aktivitetir hendir  */}
              </div>
            )}
            <div className="option-text">{this.props.myInfo.firstname}</div>
          </div>
        </div>
        {this.userPopUp()}
      </div>
    );
  }
}

export default withRouterStore(Navigation);
